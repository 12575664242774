var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-card',{attrs:{"outlined":"","tile":"","elevation":"1"}},[_c('v-card-title',[_vm._v(" Ubicación "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"blue"},on:{"click":_vm.reinitialize}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Actualizar Tabla")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"green"},on:{"click":_vm.exportExcel}},on),[_c('v-icon',[_vm._v("fas fa-file-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar Excel")])])],1),(_vm.loadTransition)?_c('div',{staticClass:"alert bluesky-line m-0 mb-0"}):_vm._e(),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headers,"items":_vm.localizacion,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"sort-by":"id"},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{staticClass:"mt-1"},[_c('v-spacer'),_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"kt-input-icon kt-input-icon--left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control form-control-md col-md-8",attrs:{"append-icon":"search","label":"Búsqueda","type":"text","placeholder":"Buscar..."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('span',{staticClass:"kt-input-icon__icon kt-input-icon__icon--left"},[_c('span',[_c('i',{staticClass:"la la-search"})])])]),_c('v-spacer'),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 btn-starkoms-primary",attrs:{"dark":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fa fa-plus mr-2"}),_vm._v(" Ubicación ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[(_vm.editedItem.id)?_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"name":"id","label":"ID","readonly":"readonly","prepend-icon":"vpn_key"},model:{value:(_vm.editedItem.id),callback:function ($$v) {_vm.$set(_vm.editedItem, "id", $$v)},expression:"editedItem.id"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'El codígo de la Ubicación es requerido!'; }],"label":"Codigo","prepend-icon":"edit_location"},model:{value:(_vm.editedItem.code),callback:function ($$v) {_vm.$set(_vm.editedItem, "code", $$v)},expression:"editedItem.code"}}),_vm._v("+ ")],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"items":_vm.warehouses,"rules":[function (v) { return !!v || 'Seleccionar una Bodega es requerido!'; }],"item-value":"id","item-text":"name","label":"Bodegas"},model:{value:(_vm.editedItem.warehouse_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "warehouse_id", $$v)},expression:"editedItem.warehouse_id"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#FF6347","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"#00FF00","text":""},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{attrs:{"to":("/inventario/locations/" + (item.id))}},[_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"#93a2dd","small":""}},on),[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Detalles de la Ubicación "+_vm._s(item.id))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"#93a2dd"},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar Ubicación "+_vm._s(item.id))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"#93a2dd"},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Eliminar Ubicación "+_vm._s(item.id))])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"#87CEFA","loading":_vm.loading},on:{"click":function($event){return _vm.reinitialize()}}},[_vm._v("Cargando ..")])]},proxy:true}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":10},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }